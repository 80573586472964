import React, { useEffect, useState, useCallback } from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { useIntl } from "gatsby-plugin-intl"
import styles from "./index.module.css"
import useMakeOrder from "./useMakeOrder"
import OrderStatus from "./OrderStatus"

declare var window: Window & typeof globalThis

/**
 * 微信支付中间页
 */
function WebChatPayMiddlePage() {
  const intl = useIntl()
  // useEffect(() => {
  //   const eruda = require('eruda');
  //   eruda.init();
  // }, []);

  const [paying, setPaying] = useState(false) //下单支付中状态标志
  const [paySuccess, setPaySuccess] = useState(false) //微信返回的支付是否成功标志
  const [orderId, wechatPayObj] = useMakeOrder(setPaying) //web 后台订单号

  //使用接口返回参数调起微信支付
  const invokeWechatPay = useCallback(() => {
    // @ts-ignore
    if (wechatPayObj && typeof WeixinJSBridge !== "undefined") {
      setPaying(true)
      // @ts-ignore
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: wechatPayObj.appId,
          timeStamp: wechatPayObj.timeStamp,
          nonceStr: wechatPayObj.nonceStr,
          package: wechatPayObj.package,
          signType: wechatPayObj.signType,
          paySign: wechatPayObj.sign,
        },
        function(res: any) {
          setPaying(false)
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            setPaySuccess(true)
          }
        }
      )
    }
  }, [wechatPayObj])

  useEffect(() => {
    // @ts-ignore
    if (typeof WeixinJSBridge == "undefined") {
      if (document.addEventListener) {
        document.addEventListener("WeixinJSBridgeReady", invokeWechatPay, false)
        // @ts-ignore
      } else if (document.attachEvent) {
        // @ts-ignore
        document.attachEvent("WeixinJSBridgeReady", invokeWechatPay)
        // @ts-ignore
        document.attachEvent("onWeixinJSBridgeReady", invokeWechatPay)
      }
    } else {
      invokeWechatPay()
    }
  }, [invokeWechatPay])

  return (
    <div
      style={{
        height: (typeof window !== `undefined` && window.innerHeight) || 867,
      }}
      className={styles.container}
    >
      {paySuccess ? (
        <OrderStatus orderId={orderId} />
      ) : (
        <div className={styles.wrapper}>
          <p className={styles.payTips}>
            {intl.formatMessage({ id: "支付订单创建成功" })}
          </p>
          <button className={styles.payButton} onClick={invokeWechatPay}>
            {paying ? (
              <span>
                {/* <Icon className={styles.resultIcon} type="loading" /> */}
                <LoadingOutlined className={styles.resultIcon} />
                {intl.formatMessage({ id: "支付中..." })}
              </span>
            ) : (
              <span>{intl.formatMessage({ id: "立即支付" })}</span>
            )}
          </button>
        </div>
      )}
    </div>
  )
}

export default React.memo(WebChatPayMiddlePage)
